import { AzureInsightsConstants } from '../app/shared/constants/azure-insights.constants';
import { CLARITY_QAS_PROJECT_ID } from '../app/shared/constants/clarity.constants';
import {
  AUTH_ISSUER_URL,
  AUTH_LOGOUT_URL,
  AUTH_QAS_SCOPE_URL,
  BACKEND_QAS_URLS,
  PROFILE_QAS_URL
} from '../app/shared/constants/url.constants';

export const environment = {
  production: false,
  backendUrls: BACKEND_QAS_URLS,
  auth: {
    clientId: 'ee73f7cc-4b06-4197-812c-84d89367fe8d',
    logoutUrl: AUTH_LOGOUT_URL,
    issuer: AUTH_ISSUER_URL,
    scope: AUTH_QAS_SCOPE_URL
  },
  monitoring: {
    enable: true,
    azureInsightsKey: AzureInsightsConstants.QAS_KEY
  },
  clarity: {
    enable: true,
    projectId: CLARITY_QAS_PROJECT_ID
  },
  profile: PROFILE_QAS_URL
};
